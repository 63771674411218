@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .hoverEffect {
    @apply duration-300 cursor-pointer;
  }
  .headerSidenavLi {
    @apply hover:underline underline-offset-2 cursor-pointer text-gray-300 hover:text-white;
  }
}

html{
  height: 100%;
  font-size: 82.5%; /* 1rem = 10px */
  overflow-x: hidden; /* Prevent horizontal scroll */
}
body {
  padding-top: 60px;
  scrollbar-color: rgb(98 84 243);
  scrollbar-width: thin;
   
  }

.container {
  width: 100%;
  margin: auto auto;
  max-width: 140rem;
  padding-right: 0 2rem  /* 16px */;
  padding-left:  0 2rem /* 16px */;
 
}
@media (max-width: 1240px) {
  .container {
    max-width: 100%;
    padding: 10px;
  }
  .navbar-list
  {
    display: none;
    column-gap: 10px;
  }
}

@media (max-width: 768px) {
  .container {
    max-width: 100%;
    padding: 10px;
  }
}


/* Additional styles for mobile view */
@media (max-width: 480px) {
  .container {
    padding: 8px;
    font-size: 14px;
  }
  .about{
    padding: 10px 0;
    align-items: center;
    font-size: small;
  }
}


@media (max-width: 767px) {
  .shop-side-nav {
    display: none;
  }
}
.about{
  padding:   0%;
}
@layer components {
  .search-bar{
    @apply w-0 group-hover:w-[500px] transition-all duration-300 rounded-full group-hover:border
     group-hover:border-gray-500 px-4 py-1 focus:outline-none dark:border-gray-800
      dark:bg-gray-900 group-hover:dark:bg-gray-800;
  }
}

@layer utilities {
  .hero-bg-color{
    @apply bg-gradient-to-r from-blue-500/80 to-green-100 dark:from-gray-800
     dark:to-gray-700 dark:text-white;
  }
}
@layer utilities {
  .banner-bg-color{
    @apply bg-gradient-to-r from-gray-400/80 to-gray-100 dark:from-gray-800
     dark:to-gray-700 dark:text-white;
  }
}
@layer utilities {
  .product-bg-color{
    @apply bg-gradient-to-r from-gray-500/60 to-gray-100 dark:from-gray-800
     dark:to-gray-700 dark:text-white;
  }
}
@layer utilities {
  .contact-bg-color{
    @apply bg-gradient-to-r from-gray-400/80 to-gray-100 dark:from-gray-900
     dark:to-gray-700 dark:text-white;
  }
}
@layer utilities {
  .about-bg-color{
    @apply bg-gradient-to-r from-blue-300/80 to-gray-100 dark:from-gray-900
     dark:to-gray-800 dark:text-white;
  }
}
/* ============= NavLink active Start here ============== */
nav .active li {
  display: flex;
  font-weight: 600;
  text-decoration: 1px underline gray;
}


.dark {
  background-color: rgb(17 24 39);
  color: #fff;
}
.nav{
  max-height: 60px;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.container.dark{
   background:  gray-800;
}

.navbar-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.3s linear;

}
.dark .navbar{
  background:  gray-800;
    color:#fff;
}
.toggles{
  display: block;
}


/*body {
  margin: auto;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  overflow: auto;
  background: rgba(50,210,244,1) 35%;
background: radial-gradient(circle, rgba(101,9,121,1) 35%,  rgba(50,210,244,1) 100%);
  animation: gradient 25s ease infinite;
  background-size: 200% 400%;
  background-attachment: fixed;

}*/

/* ============= NavLink active End here ================ */



body::-webkit-scrollbar {
  width: 1.5rem;
}

body::-webkit-scrollbar-track {
   background-color: rgb(24 24 29);
}

body::-webkit-scrollbar-thumb {
 
  background: #fff;
    border: 5px solid transparent;
    border-radius: 9px;
    background-clip: content-box;
}


h1 {
 
  font-size: rem;
  font-weight: 900;
}

 h2 {
  
   font-size: 6.4rem;
   font-weight: 300;
   white-space: normal;
  
  }

h3 {
  font-size: 3.8rem;
  font-weight: 400;
}

p, button {
  
  font-size: 1.75rem;
  line-height: 1.5;
  font-weight:200;
}

a {
  text-decoration: none;
}

li {
  list-style: none;
}


.grid {
  display: grid;
  gap: 6rem;
}

.grid-two-column {
  grid-template-columns: repeat(2, 1fr);

}

.grid-three-column {
  grid-template-columns: repeat(3, 1fr);
}

.grid-four-column{
   grid-template-columns: 1fr 1.2fr .5fr .8fr ;
}

.grid-five-column{
  grid-template-columns: repeat(5, 1fr);
}

  .common-heading {
      font-size: 3.8rem;
      font-weight: 600;
      margin-bottom: 6rem;
      text-transform: capitalize;
    }

     .intro-data {
      margin-bottom: 0;
      text-transform: uppercase;
      color: #5138ee;
    }

   .caption {
      position: absolute;
      top: 15%;
      right: 10%;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      font-size: 1.2rem;
      border-radius: 2rem;
    }

input, textarea{
    max-width: 50rem;
   
    padding: 1.6rem 2.4rem;
 ;
    text-transform: uppercase;
 
}
    input[type="submit"]{
    max-width: 16rem;
    margin-top: 2rem;
  
   
    padding: 1.4rem 2.2rem;
    border-style: solid;
    border-width: .1rem;
    text-transform: uppercase;
    font-size: 1.8rem;
    cursor: pointer;
    }


.grid-two-column , .grid-three-column, .grid-four-column{
          grid-template-columns: 1fr;
}
    
.services-1,
.services-2,
.services-3 {
  width: auto;
  height: 30rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background: pink;
  text-align: center;
  border-radius: 2rem;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.services-2 {
  gap: 4rem;
  background-color: transparent;
  box-shadow: none;

  .services-colum-2 {
    background: pink;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    border-radius: 2rem;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;

    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 1rem;
    }
  }
}
.icon {
  /* font-size: rem; */
  width: 8rem;
  height: 8rem;
  padding: 2rem;
  border-radius: 50%;
  background-color: #fff;
  color: #5138ee;
}
@media (max-width: 768px) {
  .highlights-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .highlights-grid {
    grid-template-columns: 1fr;
  }
}

.product-container {
  display: flex;
  gap: 2rem;
}

.image-section {
  flex: 1;
}

.info-section {
  flex: 1;
}

.main-image {
  width: 100%;
  height: auto;
}

.thumbnails {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.thumbnail {
  width: 60px;
  height: 60px;
  object-fit: cover;
  cursor: pointer;
}

 .scrollbar-hide::-webkit-scrollbar {
        display: none;
 }  
 
 
     







